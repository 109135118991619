<template>
	<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<v-layout row wrap class="pui-form-layout">
			<v-flex xs12 md4>
				<pui-field-set :title="getTitleFieldSet1">
					<v-layout row wrap>
						<!--<v-flex xs12 md6 lg5> -->
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('userapp.usr')"
								v-model="model.usr"
								maxlength="100"
								toplabel
								required
								:disabled="formDisabled || !isCreatingElement"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12>
							<pui-text-field
								:label="$t('userapp.name')"
								v-model="model.name"
								maxlength="200"
								toplabel
								required
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12>
							<pui-text-field
								:label="$t('userapp.email')"
								v-model="model.email"
								maxlength="200"
								toplabel
								:disabled="formDisabled"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="isCreatingElement">
						<v-flex xs12 md6>
							<input id="fakeusername" style="opacity: 0; position: absolute;" type="text" name="fakeusernameremembered" />
							<pui-text-field
								v-if="!passHidden"
								:label="$t('userapp.password')"
								v-model="model.password"
								maxlength="100"
								toplabel
								required
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:append-icon-cb="() => (hidePass = !hidePass)"
								:type="hidePass ? 'text' : 'password'"
								:disabled="formDisabled"
								:autocomplete="'new-password'"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								v-if="!passHidden"
								:label="$t('userapp.repeatpassword')"
								v-model="model.repeatpassword"
								maxlength="100"
								toplabel
								noeditable
								:append-icon="hidePass ? 'visibility_off' : 'visibility'"
								:append-icon-cb="() => (hidePass = !hidePass)"
								:type="hidePass ? 'text' : 'password'"
								required
								:disabled="formDisabled"
								:autocomplete="'new-password'"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12>
							<pui-select
								:label="$t('userapp.language')"
								v-model="model"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								modelName="puilanguage"
								:itemsToSelect="[{ isocode: model.language }]"
								:modelFormMapping="{ isocode: 'language' }"
								itemValue="isocode"
								itemText="name"
							></pui-select>
							<pui-select
								:label="$t('userapp.dateformat')"
								v-model="model"
								toplabel
								required
								:disabled="formDisabled"
								:items="[
									{ dateformat: 'yyyy/MM/dd' },
									{ dateformat: 'yyyy-MM-dd' },
									{ dateformat: 'dd/MM/yyyy' },
									{ dateformat: 'dd-MM-yyyy' }
								]"
								:itemsToSelect="[{ dateformat: model.dateformat }]"
								:modelFormMapping="{ dateformat: 'dateformat' }"
								itemValue="dateformat"
								itemText="dateformat"
							></pui-select>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md6>
							<pui-checkbox
								:label="$t('userapp.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-flex>
			<v-flex xs12 md8>
				<v-layout row wrap>
					<pui-field-set :title="getTitleFieldSet2">
						<pui-multi-select
							itemValue="profile"
							itemText="name"
							v-model="model.profiles"
							:items="this.profiles"
							:itemsToSelect="model.profiles"
							:disabled="formDisabled"
						></pui-multi-select>
					</pui-field-set>
				</v-layout>
				<v-layout row wrap>
					<pui-field-set :title="getTitleFieldSet3">
						<pui-multi-select
							itemValue="nombre"
							itemText="nombre"
							v-model="model.puertos"
							:items="this.puertos"
							:itemsToSelect="model.puertos"
							:disabled="formDisabled"
						></pui-multi-select>
					</pui-field-set>
				</v-layout>
			</v-flex>
			<v-flex xs12 md12 lg12>
				<br />
				<br />
				<br />
			</v-flex>
		</v-layout>

		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'userappform',
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'userapp',
			passHidden: false,
			hidePass: false,
			profiles: [],
			puertos: []
		};
	},
	computed: {
		getTitleFieldSet1() {
			return this.$t('userapp.title');
		},
		getTitleFieldSet2() {
			return this.$t('userapp.profiles');
		},
		getTitleFieldSet3() {
			return this.$t('userapp.puertos');
		},
		allPuertos() {
			return this.$store.state.userapp && this.$store.state.userapp.vlupmpuertolocal ? this.$store.state.userapp.vlupmpuertolocal : [];
		}
	},
	created() {
		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'userapp', requestModel: 'puiprofile', vue: this });
		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'userapp', requestModel: 'vlupmpuertolocal', vue: this });
	},
	mounted() {
		if (!this.isCreatingElement) {
			this.passHidden = true;
		}

		if (this.$store.state.userapp && this.$store.state.userapp.puiprofile) {
			this.profiles = this.$store.state.userapp.puiprofile;
		} else {
			this.getProfiles();
		}

		this.getPuertos();
	},
	methods: {
		afterSave(userModel) {
			if (userModel.usr === this.$store.getters.getUser && userModel.language !== this.$store.getters.getUserLanguage) {
				PuiFormMethodsMixin.changeAppLang(userModel.language);
			}
		},
		getProfiles() {
			const controller = '/userapp/getProfiles';
			this.$puiRequests.getRequest(
				controller,
				null,
				response => {
					if (response && response.data) {
						this.profiles = response.data;
					}
				},

				error => {
					this.onError(error);
				}
			);
		},
		getPuertos() {
			const controller = '/userapp/getPuertos';
			this.$puiRequests.getRequest(
				controller,
				null,
				response => {
					if (response && response.data) {
						this.puertos = response.data;
					}
				},

				error => {
					this.onError(error);
				}
			);
		}
	}
};
</script>
